import Swiper from "swiper";
import SwiperCore, { Navigation, Controller, Autoplay } from "swiper/core";

SwiperCore.use([Navigation, Controller, Autoplay]);

(function () {
  const expandingCardsSliders = document.querySelectorAll(".expanding-cards");
  if (!expandingCardsSliders.length) {
    return;
  }
  for (let cardContainer of expandingCardsSliders) {
    const cards = cardContainer.querySelectorAll(".expanding-card");
    for (let card of cards) {
      card.addEventListener("click", function () {
        this.classList.toggle("expanded");
        const panel = this.querySelector(".card-content");
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
      });
    }
  }
})();
